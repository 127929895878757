.my_post {
    border-top: 1px solid rgba(0, 67, 101, 0.08);
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 10px 0;
    flex-direction: column;
    background-color: white;
}

@media (min-width: 300px) and (max-width: 600px) {
    .my_post {
        width: 100%;
    }
}