.saved_list {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    width: 90%;
    margin: auto;
    border-top: 1px solid rgba(0, 67, 101, 0.08);
    background-color: white;
}

@media (min-width: 300px) and (max-width: 600px) {
    .saved_list {
    width: 100%;

    }
}