.posts_in_my_category {
    width: 90%;
    margin: auto;
    background-color: white;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-direction: column;
}

.posts_subcategory {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
    padding: 20px 0;
}


@media (min-width: 300px) and (max-width: 600px) {
    .posts_in_my_category  {
        width: 100%;
    }
}