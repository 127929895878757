/* text editor styles */
.modal-text-editor {
  display: block;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 100;
  overflow-y: auto;
  background-color: rgba(29, 24, 24, 0.3);
}
.texteditorrrr {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
}
.modal-content-text-editor {
  padding: 20px;
  gap: 30px;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 5px 50px rgba(0, 67, 101, 0.1);
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  /* border: 1px solid blue; */
}

.text-editor-left-section {
  display: flex;
  flex-direction: column;
  gap: 30px;
  /* padding: 10px; */
}

.your-upload-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: 0.005em;
  color: #202029;
}

.text-editor-select-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  flex-direction: column;
}

.quote_category {
  width: 300px;
  height: 40px;
  border: 0.5px solid #a3b0bc;
  border-radius: 10px;
  padding: 10px;
  outline: none;
}

.text-editor-select-section > select {
  width: 300px;
  height: 50px;
  border: 0.5px solid #a3b0bc;
  border-radius: 10px;
}

.text-editor-select-section > div > input {
  width: 300px;
  height: 50px;
  border-radius: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  /* border: 0.5px solid #a3b0bc; */

}
.div_for_color {
  position: relative;
}
.div_for_color > label {
  position: absolute;
  top: 15px;
  left: 5px;
  cursor: pointer;
}
.text-editor-background-colors {
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.text-editor-background-colors > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.text-editor-background-colors > div > div {
  cursor: pointer;
}

.text-editor-button {
  display: flex;
  gap: 15px;
}

.text-editor-button-discard {
  width: 130px;
  height: 35px;
  background: #e5e6eb;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}

.text-editor-button-save {
  width: 150px;
  height: 35px;
  background: #9832fe;
  box-shadow: 0px 4px 50px 4px rgba(0, 67, 101, 0.08);
  border-radius: 10px;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

.text-editor-textarea_div {
  height: 300px;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: 0px 4px 50px 4px rgba(0, 67, 101, 0.2);
  border-radius: 20px;
  width: 290px;
}
.text_editor_div_for_textarea > p {
  font-size: 20px;
}
.text_editor_div_for_textarea {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.edit_quote_textarea {
  width: 290px;
  resize: none;
  text-align: center;
  outline: none;
  height: 300px;
  border: none;
  border-radius: 20px;
  padding: 10px;
  box-shadow: 0px 5px 50px rgba(0, 67, 101, 0.2);
}

.modal-content-published_or_not {
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 5px 50px rgba(0, 67, 101, 0.1);
  border-radius: 20px;
  width: 400px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
}

.modal-content_yes_or_no {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.modal-content_yes_or_no > div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.modal-content-published-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  color: #202029;
}

.modal-content-published_or_not > button {
  width: 120px;
  height: 30px;
  background: #9832fe;
  box-shadow: 0px 4px 50px 4px rgba(0, 67, 101, 0.08);
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
}

@media (min-width: 300px) and (max-width: 600px) {
  .text-editor-select-section > select {
    height: 30px;
    width: 280px;
  }

  .text-editor-select-section > div > input {
    height: 40px;
    width: 280px;
  }
  .text-editor-select-section {
    gap: 0;
  }

  .text-editor-left-section {
    gap: 10px;
  }

  .modal-content-text-editor {
    gap: 30px;
    flex-direction: column;
    padding: 20px 10px;
  }

  .text-editor-button {
    gap: 5px;
  }

  .text-editor-textarea {
    width: 280px;
  }
  .modal-content-published_or_not {
    width: 300px;
  }
  .div_for_color > label {
    top:10px;
    font-size: 14px;
  }
  .texteditorrrr {
    margin-top: 0;
  }
}
