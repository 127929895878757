.post__item {
  width: 600px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 30px;
  padding-bottom: 20px;
}

.post__text {
  padding: 10px 50px;
  font-family: "Montserrat arm";
  font-style: normal;
  font-weight: 400;
  text-align: center;
}

.post__header {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #f3f3f3;
  position: relative;
}

.more_div button {
  background-color: transparent;
  cursor: pointer;
  border: none;
  text-align: left;
  font-size: 20px;
  box-shadow: 0px 5px 50px rgba(0, 67, 101, 0.1);
  padding: 5px;
}

.post_modal_menu {
  width: 200px;
  position: absolute;
  top: 65px;
  right: 20px;
  display: flex;
  flex-direction: column;
  padding: 20px 5px;
  border-radius: 5px;
  /* box-shadow: 0px 5px 50px rgba(0, 67, 101, 0.3); */
  background-color: white;
  z-index: 100;
  border: 1px solid rgba(0, 67, 101, 0.3);
}

.quote_info {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}

.about_post_section {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.likes_count {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #878787;
  cursor: pointer;
}
.likes_count:hover {
  text-decoration: underline;
}

.left_buttons {
  display: flex;
  align-items: center;
  gap: 20px;
}

.post_footer > div > button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #878787;
  background-color: white;
  border: none;
  cursor: pointer;
}

.post_footer {
  padding: 10px;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.post__user {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.name {
  padding-bottom: 10px;
  font-family: "Montserrat arm";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #000000;
}

.time {
  font-family: "Montserrat arm";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
}

.user_avatar {
  width: 60px;
  height: 60px;
  border-radius: 100%;
}

.comment_always {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px 20px 10px 20px;
  gap: 10px;
}

.comment_always > form {
  width: 90%;
  display: flex;
}

.add_comment_avatar {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.add_comment_input {
  font-size: 15px;
  height: 40px;
  width: 78%;
  background: #f1f2f5;
  border-radius: 100px;
  outline: none;
  border: none;
  padding: 10px;
}

.add_comment_button {
  font-size: 15px;

  width: 110px;
  height: 40px;
  padding: 8px;
  color: grey;
  border-radius: 100px;
  outline: none;
  border: none;
}

.show_more_less {
  border: none;
  background: none;
  color: #878787;
  padding-left: 10px;
  cursor: pointer;
  text-decoration: underline;
}

.show_more_less:hover {
  color: #575757;
}

.overlay_post_menu {
  display: block;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 50;
  background-color: rgba(29, 24, 24, 0.5);
}

.overlay_loading_for_post {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 2000;
  background-color: rgba(255, 255, 255, 0.1);
}

/* who like post */
.modal_likes {
  z-index: 100;
  overflow-y: auto;
  display: block;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay_likes {
  display: block;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(29, 24, 24, 0.8);
}

.modal-content-likes {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 298px;
  max-height: 400px;
  overflow-y: auto;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 5px 50px rgba(0, 67, 101, 0.1);
  border-radius: 20px;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.modal-content-likes > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.close_likes_modal {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 18px;
}

.liked_user_avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}






/* social share */

@media (min-width: 300px) and (max-width: 600px) {
  .post__item {
    width: 100%;
  }
  .user_avatar {
    width: 40px;
    height: 40px;
  }

  .name {
    padding-bottom: 5px;
    font-size: 20px;
  }

  .comment_always {
    gap: 0;
  }

  .add_comment_button {
    font-size: 11px;
  }
  .add_comment_input::placeholder {
    font-size: 11px;
  }
  .post__text {
    padding: 10px 15px;
  }
}
