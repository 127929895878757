nav {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 165px;
}

.book-icon {
  width: 35px;

}

ul {
  margin: 0;
  padding: 0;
}

nav > ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  gap: 100px;
}

nav > ul > li > a {
  color: black;
  text-decoration: none;
}

.dropdown-content > a {
  color: black;
  text-decoration: none;
  font-size: 18px;
  box-shadow: 0px 8px 16px 0px rgba(109, 108, 108, 0.2);
  border-radius: 5px;
  padding: 10px;
}

.search {
  border-radius: 30px;
  padding-left: 40px;
  width: 258px;
  height: 40px;
  border: none;
  outline: none;
}

.signin {
  background-color: #9832fe;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  width: 170px;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  border-radius: 5px;
  flex-direction: column;
  list-style: none;
  gap: 10px;
}

.dropdown:hover .dropdown-content {
  display: flex;
}

.search_input {
  position: relative;
}

.search_input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
}

.search_input > img {
  pointer-events: none;
  position: absolute;
  left: 10px;
  top: 9px;
  width: 26px;
  height: 26px;
}

.menu {
  width: 50px;
  height: 50px;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3px;
  border-radius: 100%;
  background-color: #efefef;
}

.menu > span {
  width: 16px;
  height: 2px;
  background-color: black;
}

.responsive_menu {
  width: 100%;

  padding: 30px 0;
  background: linear-gradient(
    179.25deg,
    #edcfff -35.86%,
    rgba(226, 214, 255) 26.04%,
    rgba(249, 216, 255) 55.24%,
    #ffffff 94.1%
  );
}

.resp_ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  list-style: none;
}

.resp_category {
  font-size: 24px;
}

.resp_ul > li > a {
  font-size: 24px;
  text-decoration: none;
  color: black;
}

.resp_ul > li > :hover {
  color: #9832fe;
}

.closeMenu {
  font-size: 30px;
  font-family: "Arial";
  cursor: pointer;
}

.resp_menu {
  display: none;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 1000;
}

.category_submenu {
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
  list-style: none;
}

.category_submenu > li > a {
  font-size: 16px;
  text-decoration: none;
  color: black;
}

.category_submenu > li > a:hover {
  color: #9832fe;
}

.dropdown_resp:hover .category_submenu {
  display: flex;
}

.profile_pic_menu {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.login_user_menu {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  position: relative;
}

.settings {
  text-decoration: none;
  color: black;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  line-height: 16px;
}

.log_out {
  padding: 10px 0;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #202029;
  background: none;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.over {
  display: block;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(80, 70, 70, 0.3);
  z-index: 100;
  /* z-index: 101; */
}

.privace_poilcy_txt {
  text-decoration: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #202029;
  display: flex;
  align-items: center;
}

.modal_menu_for_login {
  background-color: #ffffff;
  box-shadow: 0px 5px 50px rgba(40, 44, 46, 0.08);
  width: 194px;
  height: 200px;
  position: absolute;
  top: 55px;
  right: -30px;
  border-radius: 16px;
  background-color: white;
  padding: 0 10px;
  list-style: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  font-size: 16px;
  color: #202029;
  z-index: 100;
}

.modal_menu_for_login > a {
  padding: 10px 0;
}

.login_user_menu > button {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 0;
  outline: 0;
  cursor: pointer;
}

/* notfications styles */

.overlay_notfications {
  display: block;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(29, 24, 24, 0.3);
  z-index: 100;
}
.modal_notfications {
  padding: 20px;
  position: absolute;
  top: 60px;
  right: 0px;
  background-color: white;
  width: 416px;
  max-height: 577px;
  z-index: 1000;
  border-radius: 20px;
  box-shadow: 0px 5px 50px rgba(0, 67, 101, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
}
.modal_notfications > div {
  width: 100%;
}

.notif_list {
  overflow-y: auto;
  display: flex;
  gap: 15px;
  flex-direction: column;
  cursor: pointer;
}

.notif_list p {
  font-size: 16px;
  line-height: 23px;
}

.notif_list span {
  font-weight: bold;
  color: #9832fe;
}

.notfications_item_all {
  display: flex;
  flex-direction: column;
  line-height: 10px;
}

.notification_item {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
}
.notif_info {
  display: flex;
  gap: 10px;
}
.notification_user_avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.notifications_modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notifications_modal_header > h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #202029;
}

.notifications_modal_header > button,
.notifications_modal_footer > button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #9832fe;
  border: none;
  background: none;
  cursor: pointer;
}

.notifications_modal_footer {
  text-align: center;
}

.unread {
  width: 10px;
  height: 10px;
  background: #9832fe;
  border-radius: 50%;
}

/* modal for notfications upload */

.modal_see_notif_info {
  z-index: 1001;
  overflow-y: auto;
  display: block;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(54, 49, 49, 0.8);


}

.modal-content-see-notif-info{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 100px;
}


.notif_time {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.02em;
  margin-left: 45px;
}

.notfications_item_all > span {
  color: #616366;

}
@media (min-width: 601px) and (max-width: 800px) {
  nav {
    gap: 40px;
  }
  nav > ul {
    gap: 10px;
  }
  .right-list {
    gap: 10px;
  }
}

@media (min-width: 801px) and (max-width: 1150px) {
  nav {
    gap: 95px;
  }
  nav > ul {
    gap: 45px;
  }
  .right-list {
    gap: 45px;
  }
}

@media (min-width: 300px) and (max-width: 600px) {
  .menu-list,
  .upload-btn {
    display: none;
  }

  nav > ul {
    gap: 0px;
  }

  nav {
    gap: 40px;
  }

  .search {
    width: 200px;
    height: 32px;
    padding-left: 40px;
  }

  .book-icon {
    width: 30px;
  }

  .login_user_menu > button {
    width: 40px;
    height: 40px;
    padding: 0;
  }

  .profile_pic_menu {
    width: 40px;
    height: 40px;
  }

  .search_input > img {
    left: 15px;
    top: 5px;
  }

  .right-list {
    gap: 20px;
  }

  .menu {
    display: flex;
    width: 40px;
    height: 40px;
  }

  .resp_menu {
    display: block;
  }
  .modal_notfications {
    top: 50px;
    right: -84px;
    width: 300px;
  }

  .notif_list p {
    font-size: 14px;
  }
  .notifications_modal_header > h3 {
    font-size: 20px;
  }
  .notifications_modal_header > button,
  .notifications_modal_footer > button {
    font-size: 14px;
  }
  .modal-content-see-notif-info{
    /* transform: translate(-50%, -29%); */
    width: 100%;
  }
}
