.profilePage {
  width: 90%;
  margin: auto;
  background-color: white;
}

.profile_background {
  width: 100%;
  /* background-repeat: no-repeat;
    background-size: cover; */
  height: 400px;
  border-radius: 0px 0px 10px 10px;
  position: relative;
  overflow: hidden;
}
.background_image {
  object-fit: cover;
}
#profile_photo,
#cover_photo {
  visibility: hidden;
}

.edit_profile_pic {
  position: absolute;
  left: 170px;
  top: -15px;
  width: 40px;
  height: 40px;
  background-color: #e5e6eb;
  border: none;
  border-radius: 100%;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}

.edit_cover_photo_button {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 239px;
  height: 40px;
  background-color: #e5e6eb;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.pic_div {
  position: relative;
}

.autor_pic {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  position: absolute;
  top: -160px;
  left: 50px;
  box-shadow: -5px -6px 16px 3px #0000006b;
}

.nameSurnameDiv {
  margin-top: -80px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.nameSurnameDiv > h3 {
  font-size: 22px;
}

.pen_icon {
  width: 16px;
  height: 16px;
}

.name_and_pic {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.my_info {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
}

.edit_btn {
  cursor: pointer;
  font-size: 20px;
  width: 180px;
  height: 48px;
  background: #9832fe;
  box-shadow: 0px 4px 50px 4px rgba(0, 67, 101, 0.08);
  border-radius: 10px;
  border: none;
  color: white;
}

.profile_menu {
  width: 100%;
  border-top: 1px solid #cccccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  position: relative;
  font-size: 18px;
  padding: 0 10px;
}

.profile_menu > ul {
  list-style: none;
}

.profile_menu > ul > li > a {
  font-family: "Montserrat arm";
  font-style: normal;
  text-decoration: none;
  font-size: 18px;
  line-height: 18px;
  padding-bottom: 24px;
  color: #878787;
}

.profile_menu_list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.image_crop {
  background-color: white;
  width: 300px;
  height: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image_crop_back {
  width: 800px;
  height: 400px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay_avatar {
  display: block;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(29, 24, 24, 0.8);
}

.overlay_cover {
  display: block;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(29, 24, 24, 0.8);
}

.first_name_input {
  border: 0.5px solid #a3b0bc;
  border-radius: 15px;
  height: 25px;
  padding: 0 10px;
  outline: none;
  width: 140px;
}

.last_name_input {
  border: 0.5px solid #a3b0bc;
  border-radius: 15px;
  height: 25px;
  padding: 0 10px;
  outline: none;
  width: 180px;
}

.change_name_surname_info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  gap: 5px;
}

.submit_info_changes {
  border: none;
  border-radius: 15px;
  height: 25px;
  outline: none;
  width: 100px;
  background: #9832fe;
  color: white;
}
@media (min-width: 300px) and (max-width: 600px) {
  .autor_pic {
    width: 100px;
    height: 100px;
    top: -77px;
    left: -7px;
  }

  .edit_profile_pic {
    width: 30px;
    height: 30px;
    top: -7px;
    left: 67px;
  }

  .nameSurnameDiv {
    margin-left: -143px;
    margin-top: -19px;
  }

  .my_info {
    height: 100px;
    gap: 0;
  }

  .nameSurnameDiv > h3 {
    font-size: 15px;
  }
  .pen_icon {
    width: 14px;
    height: 14px;
  }

  .edit_cover_photo_button {
    width: 150px;
    height: 30px;
    font-size: 12px;
  }

  .image_crop_back {
    width: 100%;
  }

  .profilePage {
    width: 100%;
  }
  .change_name_surname_info {
    flex-direction: column;
    padding: 5px 25px;
    gap: 2px;
  }
  .image_crop_back {
    width: 100%;
    height: 200px;
  }
}
@media (min-width: 300px) and (max-width: 482px) {
  .change_name_surname_info {
    flex-direction: column;
    padding: 5px 15px;
    gap: 2px;
  }
  .first_name_input {
    width: 130px;
  }

  .last_name_input {
    width: 130px;
  }
  .submit_info_changes {
    width: 130px;
  }
}
@media (min-width: 483px) and (max-width: 600px) {
  .nameSurnameDiv {
    margin-left: -128px;
    margin-top: -10px;
  }
}
@media (min-width: 601px) and (max-width: 810px) {
  .nameSurnameDiv {
    margin-left: -50px;
    margin-top: -70px;
  }
}

@media (min-width: 483px) and (max-width: 810px) {

  .change_name_surname_info {
    flex-direction: column;
    padding: 5px 25px;
    gap: 2px;
  }
  .nameSurnameDiv > h3 {
    padding-left: 8px;
}
  .first_name_input {
    width: 180px;
  }

  .last_name_input {
    width: 180px;
  }
  .submit_info_changes {
    width: 180px;
  }
}
