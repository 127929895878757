.about_us_section {
  max-width: 500px;
  box-shadow: 0px 8px 16px 0px rgba(82, 16, 82, 0.501);
  border-radius: 20px;
  padding: 100px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 20px auto;
  gap: 30px;
  color: rgb(60, 55, 55);
}
