.modalUnauthorized{
    padding:20px;
    position: absolute;
    top: 9%;
    left: 61%;
    transform: translate(-50%, -50%);
    width: 300px;
    background: #FFFFFF;
    box-shadow: 0px 5px 50px rgb(0 67 101 / 10%);
    border-radius: 20px;
    z-index: 102;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}
.btn-modal-unauthorized{
    z-index: 103;
}
.overlayUnauthorized{
    display: block;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background-color:rgba(49,49,49,0.8);
    z-index: 101;
}
.err{
    color:blueviolet
}

.unauthorizedOkBtn {
    color: white;
    background-color:blueviolet;
    border: none;
    outline: none;
    padding: 10px 50px;
    border-radius: 50px;
}
@media (min-width: 300px) and (max-width: 600px) {
.modalUnauthorized{
    width: 290px;
    top: 148px;
    left: 50%;
}
}