.btn-modal {
  border: none;
  background: none;
  cursor: pointer;
}

.modal {
  z-index: 100;
  overflow-y: auto;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(29, 24, 24, 0.8);
}


.modal-content {
  margin:80px auto;
  position: relative;
  width: 485px;
  height: 650px;
  background: #ffffff;
  box-shadow: 0px 5px 50px rgba(0, 67, 101, 0.1);
  border-radius: 20px;
}

.close {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 20px;
}

.upload_form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  /* border: 1px solid red; */
}

.upload_all_content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.choose_image_or_text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  text-align: center;
}

.language_select_div > select {
  width: 317px;
  height: 30px;
  border: none;
  box-shadow: 0px 4px 50px rgba(0, 67, 101, 0.2);
  border-radius: 20px;
  padding:  0 10px;
  outline: none;
}
.upload_image_div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.upload_text_div > textarea {
  padding: 15px;
  width: 317px;
  height: 200px;
  resize: none;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 4px 50px rgba(77, 124, 148, 0.2);
  outline: none;
}

.choose_image_or_text > p {
  font-size: 25px;
}

.choose_image_or_text > button {
  width: 120px;
  height: 30px;
  background-color: #7f51aca3;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 20px;
}
.category_div {
  position: relative;
}

.search_img {
  position: absolute;
  top: 17px;
  right: 15px;
  z-index: 200;
}

.imageDiv {
  width: 100px;
  height: 100px;

  background: #ffffff;
  box-shadow: 0px 4px 50px rgba(0, 67, 101, 0.08);
  border-radius: 30px;
}

#files {
  display: none;
}

.fileLabel {
  cursor: pointer;
}

.fileRequired {
  font-size: 12px;
  padding-left: 10px;
  color: red;
}

.authorName {
  padding: 15px;
  font-size: 20px;
  width: 317px;
  height: 48px;
  /* background: #ffffff; */
  box-shadow: 0px 4px 50px 1px rgba(0, 67, 101, 0.08);
  border-radius: 30px;
  border: none;
  outline: none;
}

.authorNameErr {
  font-size: 12px;

  color: red;
}

.bookName {
  border: none;
  outline: none;
  padding: 15px;
  font-size: 20px;
  width: 317px;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 4px 50px 1px rgba(0, 67, 101, 0.08);
  border-radius: 30px;
}

.bookNameErr {
  font-size: 12px;
  color: red;
}

.bookCategory {
  border: none;
  outline: none;
  padding: 15px;
  padding-right: 40px;
  font-size: 20px;
  width: 317px;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 4px 50px 1px rgba(0, 67, 101, 0.08);
  border-radius: 30px;
}

.suggestions {
  position: absolute;
  overflow: scroll;
  overflow-x: hidden;
  width: 317px;
  height: 200px;
  top: 48px;

  background: #ffffff;
  box-shadow: 0px 4px 50px 1px rgba(0, 67, 101, 0.08);
  border-radius: 30px;
}

.suggestions::-webkit-scrollbar {
  width: 0;
}

.suggestedWords {
  padding: 16px 0 16px 16px;
  display: flex;
  align-items: center;
  height: 38px;
  font-size: 19px;
  cursor: pointer;
}

.suggestedWords:hover {
  background-color: rgba(152, 50, 254, 0.16);
}

.searches {
  position: absolute;
  overflow: scroll;
  overflow-x: hidden;
  width: 317px;
  height: 200px;
  background: #ffffff;
  box-shadow: 0px 4px 50px 1px rgba(0, 67, 101, 0.08);
  border-radius: 30px;
}

.searches::-webkit-scrollbar {
  width: 0;
}

.searchedWords {
  padding: 16px 0 16px 16px;
  display: flex;
  align-items: center;
  height: 38px;
  font-size: 19px;
}

.searchedWords:hover {
  background-color: rgba(152, 50, 254, 0.16);
}

.categoryErr {
  font-size: 12px;
  color: red;
}

.submit {
  width: 317px;
  height: 48px;
  background: #9832fe;
  box-shadow: 0px 4px 50px 4px rgba(0, 67, 101, 0.08);
  border-radius: 30px;
  border: none;
  outline: none;
  font-size: 25px;
  cursor: pointer;
  color: #ffffff;
}

/* Phone */

@media (min-width: 300px) and (max-width: 600px) {
  .modal-content {
    width: 300px;
    height: 560px;
  }
  .upload_form {
    width: 250px;
  }
  .upload_form > div > input {
    width: 100%;
  }
  .submit {
    width: 100%;
  }
  .suggestions {
    width: 250px;
  }
  .searches {
    width: 250px;
  }
  .choose_image_or_text > p {
    font-size: 19px;
  }

  .upload_text_div > textarea {
    width: 250px;
  }
  .language_select_div > select {
    width: 250px;
  }
}
