.social_share_modal {
    display: block;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 100;
    overflow-y: auto;
  }

.social_share_overlay {
  display: block;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(29, 24, 24, 0.5);

}

.social_share_modal_content {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  padding: 30px;
  width: 295px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px
}

.close_share{
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 18px;
}
