.modal_content_delete_acc {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    box-shadow: 0px 5px 50px rgba(0, 67, 101, 0.1);
    border-radius: 20px;
    padding: 50px;
    width: 500px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
}

.modal_content_delete_acc>form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.modal_content_delete_acc>form>label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #3D424E;
}

.password_error, .error_for_change_pass {
    color: red;
}

.modal_content_delete_acc>form>input {
    width: 250px;
    height: 35px;
    border: 0.5px solid #A3B0BC;
    border-radius: 20px;
    padding: 5px 20px;
    outline: none;
}

.delete_button {
    width: 250px;
    height: 35px;
    background: #EA4335;
    box-shadow: 0px 4px 50px 4px rgba(0, 67, 101, 0.08);
    border-radius: 30px;
    border: none;
    outline: none;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    padding: 5px 15px;
}

.delete_button_google_fb {
    width: 250px;
    height: 35px;
    left: 438px;
    top: 777px;
    background: #EA4335;
    box-shadow: 0px 4px 50px 4px rgba(0, 67, 101, 0.08);
    border-radius: 30px;
    border: none;
    outline: none;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    cursor: pointer;
}

.modal_delete_acc {
    display: block;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 100;

}

.close_delete_acc {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 10px;
    font-size: 20px;
}

.overlay_delete_acc {
    display: block;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background-color: rgba(29, 24, 24, 0.8);

}


.account_settings_div {
    margin: 20px auto;
    width: 500px;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    box-shadow: 0px 4px 50px 4px rgba(67, 43, 65, 0.2);
}


.account_settings {
    /* font-family: 'Poppins';
    font-style: normal; */
    font-weight: 500;
    font-size: 32px;
    line-height: 32px;
    color: #202029;
}

.want_delete {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #EA4335;
    text-decoration: underline;
    background: none;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
}


.delete_profile {
    display: flex;
    flex-direction: column;
    gap: 20px;
}








.modal_sure {
    display: block;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 100;

}



.overlay_sure {
    display: block;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background-color: rgba(29, 24, 24, 0.8);

}


.modal_content_sure {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    box-shadow: 0px 5px 50px rgba(0, 67, 101, 0.1);
    border-radius: 20px;
    width: 250px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
    font-size: 18px;
}

.sure_buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px
}

.sure_buttons>button {
    border: none;
    background: #9832FE;
    color: white;
    padding: 8px 20px;
    font-size: 14px;
    border-radius: 10px;
    cursor: pointer;
}




/* change_password */

.change_password>form {
    width: 240px;
    display: flex;
    justify-self: center;
    flex-direction: column;
    /* gap: 10px */
}

.change_password>form> div>input {
    width: 100%;
    height: 40px;
    border: 0.5px solid #202029;
    border-radius: 30px;
    padding: 10px 20px;
    outline: none;
    margin-top: 10px;
}
.change_password>form> div {
    position: relative;
}
.change_password>form> div >svg {
position: absolute;
top: 22px;
right: 10px;
}
.change_password>form >span {
    color: red;
    font-size: 12px;
}

.change_password_button {
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 30px;
    outline: none;
    background: #9832FE;
    color: white;
    cursor: pointer;
    margin-top: 10px;
}

.successText {
    color: white;
    margin-top: 10px;
    padding: 10px;
    background-color: green;
}


.forgot_btn_setting {
    text-align: left;
    margin-top: 10px;
    background-color: white;
    border: none;
    color: #9832fe;
    cursor: pointer;
}

@media (min-width: 300px) and (max-width: 600px) {
.account_settings_div {
    width: 100%;
}

.modal_content_delete_acc {
    width: 100%;
    padding: 30px;

}
}