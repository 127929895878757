.comment {
    margin-bottom: 10px;
}

.name_and_body {
    background: #F1F2F5;
    padding: 8px 15px;
    border-radius: 30px;
    width: 80%;
}

.edit_show_hide_buttons {
    display: flex;
    padding: 5px 0;
    margin-left: 90px;
    gap: 20px
}

.edit_show_hide_buttons>button {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #878787;
    border: none;
    background: transparent;

}

.comment_user_and_body {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    gap: 10px;
    position: relative;

}

.username {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
}

.comment_body {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    width: 100%;
}

.comment_options {
    background: none;
    color: #878787;
    border: none;
    font-size: 20px;
}

.comment_options_modal {
    position: absolute;
    width: 127px;
    background: #FFFFFF;
    box-shadow: 0px 5px 50px rgba(40, 44, 46, 0.08);
    border-radius: 16px;
    z-index: 101;
    padding: 20px 10px;
    top: 39px;
    right: 7px;
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.comment_options_modal>button {
    border: none;
    background: none;
    box-shadow: 0px 5px 50px rgba(40, 44, 46, 0.08);
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 20px;
    color: #878787;
    text-align: left;
    padding: 5px;
}

.not_login_user {
    background: none;
    border: none;
    width: 21px;
}

.edit_comment_input {
    width: 80%;
    border-radius: 15px;
    height: 30px;
    border: none;
    outline: none;
    margin-top: 2px;
    padding: 0 10px;
}

.done_btn {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.children {
    margin-left: 30px;
    margin-bottom: 10px;
}

.reply_input {
    width: 67%;
    border-radius: 15px;
    height: 30px;
    border: none;
    outline: none;
    margin-bottom: 5px;
    margin-top: 2px;
    padding: 0 10px;
    background: #F1F2F5;
}

.reply_btn {
    height: 30px;
    border-radius: 15px;
    padding: 8px;
    border: none;
    background: #F1F2F5;
    cursor: pointer;
    color: #878787;
    width: 99px;

}
