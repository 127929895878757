.google {
  border-radius: 100%;
  width: 48px;
  height: 48px;
  background-color: white;
  border: 0.5px solid #A3B0BC;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}



