
.modal-signin-signup{
  z-index: 100;
  overflow-y: auto;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(29, 24, 24, 0.8);
}

.close {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 20px;
}

.modal-content-sign {
  margin:80px auto;
  position: relative;
  padding: 40px 40px;
  width: 500px;
  background: #ffffff;
  box-shadow: 0px 5px 50px rgba(0, 67, 101, 0.1);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

}

.textEmail {
  color: #9832fe;
}

.okBtn {
  margin-top: 10px;
  background-color: #9832fe;
  border: none;
  color: white;
  width: 100px;
  height: 30px;
  border-radius: 20px;
  outline: none;
  border: none;
}

.forgot_btn {
  margin-top: 10px;
  background-color: white;
  border: none;
  color: #9832fe;
  cursor: pointer;
}

.signin {
  color: white;
  cursor: pointer;
}

.submit_and_forgot {
  text-align: right;
}

.forgot_header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 48px;
  color: #202029;
  padding-bottom: 20px;
}

.forgot_text {
  text-align: center;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.forgot_text_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #9832fe;
}

.form_style {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 0 10px;
}

.form_style > div {
  width: 100%;
}

.form_style > div span {
  color: red;
  padding-top: 10px;
}

.form_style div label {
  font-family: "Montserrat arm";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #3d424e;
}

.form_style div input {
  font-family: "Montserrat arm";
  font-style: normal;
  width: 100%;
  height: 40px;
  padding: 10px 35px 10px 20px;
  overflow: hidden;
  border-radius: 30px;
  border: 1px solid #a3b0bc;
  outline: none;
  font-size: 20px;
  color: #3d424e;
  margin-top: 8px;
}

#submit-btn {
  width: 100%;
  height: 48px;
  background: #9832fe;
  box-shadow: 0px 4px 50px 4px rgba(0, 67, 101, 0.08);
  border-radius: 30px;
  border: none;
  outline: none;
  font-size: 25px;
  color: #ffffff;
  cursor: pointer;
  padding: 0;
}

#send-btn {
  width: 100%;
  height: 48px;
  background: #9832fe;
  box-shadow: 0px 4px 50px 4px rgba(0, 67, 101, 0.08);
  border-radius: 30px;
  border: none;
  outline: none;
  color: #ffffff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  padding: 0;
}

.verify_email_content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
}

.verify_email_content > div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  width: 100%;

}

.verify_text {
  font-size: 18px;
  color: #3d424e;
}

.verify_email_content > div > button {
  background-color: #9832fe;
  width: 190px;
  height: 35px;
  box-shadow: 0px 4px 50px 4px rgba(0, 67, 101, 0.08);
  border-radius: 30px;
  border: none;
  outline: none;
  color: #ffffff;
  padding: 10px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-size: 18px;
  cursor: pointer;
  padding: 0;
  line-height: 18px;
}

.password_div {
  position: relative;
}

.password_eye {
  position: absolute;
  top: 38px;
  right: 15px;
  user-select: none;
  cursor: pointer;
}

.btn-acc {
  border: none;
  outline: none;
  font-size: 14px;
  background: none;
  text-decoration: underline;
  cursor: pointer;
}

.buttonOk {
  color: #9832fe;
}
.forgot_pass_submit_btn {
  cursor: pointer;
}
.forgotBtn {
  color: #9832fe;
  border: none;
  background-color: white;
  cursor: pointer;
  margin-left: 245px;
}

.link_sent {
  color: red;
  font-size: 18px;
}

.login_error {
  color: red;
}



.googlefacebook {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
@media (min-width: 300px) and (max-width: 600px) {
  .btn-modal {
    font-size: 24px;
    text-decoration: none;
    color: black;
  }
}

@media (min-width: 300px) and (max-width: 600px) {
  .modal-content-sign {
    width: 300px;
    padding: 20px 5px;
  }
  .verify_email_content > div > button {
    font-size: 12px;
  }
  .verify_text {
    text-align: center;
    font-size: 16px;
  }
  .form_style div input {
    height: 35px;
    margin-top: 5px;
    font-size: 16px;
  }
  .form_style {
    gap: 10px;
  }
  .textEmail {
    font-size: 14px;
    text-align: center;
  }
  #submit-btn {
    height: 35px;
    font-size: 16px;
  }
  .password_eye {
    top: 33px;
  }
  .forgot_header  {
    font-size: 30px;
  }
  .forgot_text_text {
    font-size: 14px;
  }
  #send-btn {
    height: 35px;
    font-size: 16px;
  }
}
