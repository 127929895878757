.forgot_pass_div {
    width: 600px;
    /* height: 570px; */
    background: #FFFFFF;
    box-shadow: 0px 5px 50px rgba(0, 67, 101, 0.1);
    border-radius: 30px;
    margin: 100px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    font-family: 'Poppins';
    font-style: normal;
    padding: 20px 0;
}

.forgot_pass_div>h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 48px;
    color: #202029;
}

.forgot_pass_div>p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #9832FE;
}

.forgot_form {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.forgot_form>div>label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #3D424E;
}

.forgot_form>div>input {
    margin-top: 10px;
    width: 100%;
    height: 48px;
    border: 0.5px solid #A3B0BC;
    border-radius: 30px;
    color: #202029;
    padding: 20px 35px 20px 20px;
    outline: none;
    font-size: 20px;

}

.forgot_form>div>span {
    width: 101px;
    height: 11px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 10px;
    color: #EA4335;
}

#forgot_pass_btn_submit {
    width: 300px;
    height: 48px;
    background: #9832FE;
    box-shadow: 0px 4px 50px 4px rgba(0, 67, 101, 0.08);
    border-radius: 30px;
    color: white;
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
}

.password_div_forgot {
    position: relative;
}

.password_eye_forgot {
    position: absolute;
    top: 45px;
    right: 15px;
    user-select: none;
}


@media (min-width: 300px) and (max-width: 600px) {
    .forgot_pass_div {
        width: 100%;
    }
    .forgot_pass_div>h1 {
        font-size: 24px;
    }
    .forgot_form>div>input {
        height: 40px;
    }
    .password_eye_forgot {
        top: 42px;
    }
    #forgot_pass_btn_submit {
        height: 40px;
    }
}