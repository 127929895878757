
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-wrap: break-word;
  /* word-break: break-all; */
  /* user-select: none; */

}
#root{background: linear-gradient(179.25deg, #EDCFFF -35.86%, rgba(226, 214, 255, 0.95) 26.04%, rgba(249, 216, 255, 0.48) 55.24%, #FFFFFF 94.1%);}